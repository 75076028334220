import {red} from '@material-ui/core/colors';
import '../index.css';

export const tailwind = {};

export const mui = {
    palette: {
        primary: {
            main: '#FBBD2A',
        },
        secondary: {
            main: '#2663E9',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#FFFFFF',
        },
    },
};

export const theme = 'theme-killbills';