const routes=[
    {path: "/user/forgot-password", screen: 'forgot_password', secured: false},
    {path: '/', exact: false, screen: 'app', routes: [
        {path: '/', screen: 'home', exact: true},
        {path: '/', exact: false, screen: 'crud_app', routes: [
                {path: "/searchtext", screen: 'search_text'},
                {path: "/receipts/:id", screen: 'receipt'},
                {path: "/receipts", screen: 'receipts'},
                {path: "/matches/:id", screen: 'match'},
                {path: "/matches", screen: 'matches'},
                {path: "/user/profile/edit", screen: 'edit_current_user'},
                {path: "/user/profile", screen: 'user_profile'},
                {path: '/', screen: 'home', exact: true},
                {screen: 'not_found', exact: false, secured: false},
            ]},
        ]},
];
export default routes