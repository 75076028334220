// noinspection JSUnusedGlobalSymbols
export const FIND_CURRENT_MERCHANT_RECEIPTS = (gql: any) => gql`
    query findCurrentMerchantReceipts($offset: String, $limit: Int) {
        findCurrentMerchantReceipts(offset: $offset, limit: $limit) {
            cursor
            items {
                id
                covers
                payments{lastFour, bin, scheme}
                items{taxAmount, taxDescription}
                taxAmount
                taxDescription
                currency
                match
                amount
                storeName
                merchantName
                partnerName
                date
                createdAt
                updatedAt
                status
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const GET_CURRENT_USER = (gql: any) => gql`
    query getCurrentUser {
        getCurrentUser {
            id
            email
            lastConnexionDate
            firstName
            lastName
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const UPDATE_CURRENT_USER = (gql: any) => gql`
    mutation updateCurrentUser($data: UpdateCurrentUserInput!) {
        updateCurrentUser(data: $data) {
            id
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const GET_CURRENT_MERCHANT = (gql: any) => gql`
    query getCurrentMerchant {
        getCurrentMerchant {
            id
            name
            hmac
            statMatches
            statReceipts
            statStores
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const LOGIN = (gql: any) => gql`
    mutation login($data: CreateAuthTokenInput!) {
        createAuthToken(data: $data) {
            token
            refreshToken
        }
    }
`;
export const GET_MATCH = (gql: any) => gql`
    query getMatch($id: ID!) {
        getMatch(id: $id) {
            amount
            bank
            bankName
            createdAt
            id
            merchant
            merchantName
            payment
            receipt
            reference
            status
            StoreLegitNumber
            storeName
            tags
            transaction
            transactionConsumer
            transactionExtRef
            updatedAt
        }
    }
`;
// noinspection JSUnusedGlobalSymbols
export const MAKE_STATS = (gql: any) => gql`
    query compound($dayOne: Float!, $today: Float!, $dayTwo: Float!) {
        receiptsCount24h: searchReceipts(
            offset: "0"
            query: {
                criteria: [{field: "createdAt", floatListValue: [$dayOne, $today], operator: "range", type: "float[]"}]
            }
        )  {
            count
        }
        receiptsCount48h: searchReceipts(
            offset: "0"
            query: {
                criteria: [{field: "createdAt", floatListValue: [$dayTwo, $dayOne], operator: "range", type: "float[]"}]
            }
        )  {
            count
        }
        searchReceipts{count}
        transactionsCount24h: searchTransactions(
            offset: "0"
            query: {
                criteria: [{field: "createdAt", floatListValue: [$dayOne, $today], operator: "range", type: "float[]"}]
            }
        )  {
            count
        }
        transactionsCount48h: searchTransactions(
            offset: "0"
            query: {
                criteria: [{field: "createdAt", floatListValue: [$dayTwo, $dayOne], operator: "range", type: "float[]"}]
            }
        )  {
            count
        }
        searchTransactions{
            count
            items {
                createdAt
            }}
        matchesCount24h: searchMatches(
            offset: "0"
            query: {
                criteria: [{field: "createdAt", floatListValue: [$dayOne, $today], operator: "range", type: "float[]"}]
            }
        )  {
            count
        }
        matchesCount48h: searchMatches(
            offset: "0"
            query: {
                criteria: [{field: "createdAt", floatListValue: [$dayTwo, $dayOne], operator: "range", type: "float[]"}]
            }
        )  {
            count
        }
        searchMatches{count}
        searchConsumers{count}
        searchStores{count}
        histogramTransactions{buckets}
        histogramReceipts{buckets}
    }
`;

// noinspection JSUnusedGlobalSymbols
export const GET_RECEIPT = (gql: any) => gql`
    query getReceipt($id: ID!) {
        getReceipt(id: $id) {
            amount
            archivedAt
            authCode
            id
            createdAt
            covers
            currency
            date
            degradedReference
            externalReference
            hashedExternalReference
            footerTicket
            headerTicket
            invoice
            qrCode
            items {
                amount
                createdAt
                currency
                description
                discount
                id
                itemName
                parent
                quantity
                status
                subItems {
                    amount
                    createdAt
                    currency
                    description
                    discount
                    id
                    itemName
                    parent
                    quantity
                    status
                    tags
                    taxAmount,
                    taxDescription
                    taxRate
                    type
                    updatedAt
                }
                tags
                taxAmount, 
                taxDescription
                taxRate
                type
                updatedAt
            }
            match
            matchable
            matchedAt
            merchant
            merchantName
            mode
            partnerName
            payloadId
            payments {
                amount,
                authCode,
                lastFour, 
                bin,
                matchable
                paymentName,
                scheme,
                transactionDate,
                transactionExternalId,
                transactionId
            }
            reference
            saleMethod
            scheme
            status
            store
            storeAddress
            storeFooterTicket
            storeHeaderTicket
            storeLegitNumber
            storeName
            table
            tags
            taxAmount
            taxDescription
            taxes {
                taxAmount, 
                taxDescription
                taxRate
            }
            totalDiscount
            updatedAt
            kb_features
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const FIND_STORES = (gql: any) => gql`
    query findStores {
        findStores {
            items {
                id
                name
                merchant
                merchantName
                externalReference
                createdAt
                updatedAt
                address
                location
                status
                tags
                siret
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const SEARCH_CURRENT_MERCHANT_RECEIPTS = (gql: any) => gql`
    query searchCurrentMerchantReceipts($offset: String, $limit: Int, $sort: String) {
        searchCurrentMerchantReceipts(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            items {
                id
                covers
                payments{lastFour, bin, scheme}
                items{taxAmount, taxDescription}
                taxAmount
                taxDescription
                currency
                match
                amount
                storeName
                merchantName
                partnerName
                date
                createdAt
                updatedAt
                status
            }
        }
    }
`;
// noinspection JSUnusedGlobalSymbols
export const SEARCH_CURRENT_MERCHANT_MATCHES = (gql: any) => gql`
    query searchCurrentMerchantMatches($offset: String, $limit: Int, $sort: String) {
        searchCurrentMerchantMatches(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            items {
                id
                createdAt
                updatedAt
                storeName
                amount
            }
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const GET_USER = (gql: any) => gql`
    query getCurrentUser {
        getCurrentUser {
            id
            email
            lastConnexionDate
            firstName
            lastName
        }
    }
`;

// noinspection JSUnusedGlobalSymbols
export const UPDATE_USER = (gql: any) => gql`
    mutation updateUser($id: ID!, $data: UpdateUserInput!) {
        updateUser(id: $id, data: $data) {
            id
        }
    }
`;
