const translations = {
    'fr-FR': {
        translation: {
            error_404: 'La page demandée n\'existe pas ou a été supprimée',
            role_user_label: 'Utilisateur',
            role_admin_label: 'Administrateur',
            role_owner_label: 'Propriétaire',
            choose_a_type: 'Choisir un type',
            choose_an_operator: 'Choisir un opérateur',
            add_a_condition: 'Filtre',
            search: 'Chercher',
            reset_search: 'Réinitialiser',
            Field: 'Champs',
            Value: 'Value',
            Remove: 'Supprimer',
            Search_by_id: 'Chercher par ID',
            'By id': 'Par ID',
            'Query builder': 'Constructeur de requêtes',
            'yes': 'Oui',
            'no': 'Non',
            column_POS_label: 'POS ',
            // field_query_select_label: 'Sélection',
            field_user_label: 'Utilisateur',
            field_owner_label: 'Propriétaire',
            field_organization_label: 'Organisation',
            field_role_label: 'Rôle',
            field_platform_label: 'Plateforme',
            field_membership_label: 'Adhérant',
            field_bank_label: 'Banque',
            field_merchant_label: 'Merchant',
            field_partner_payload_partner_label: 'Partenaire :',
            field_partner_payload_dbId_label: 'ID en BDD',
            field_partner_payload_status_label: 'Statut :',
            modal_confirm_delete_partnerspayload_title: 'Supprimer le payload ?',
            modal_confirm_delete_partnerspayload_text: 'Cette action est irreversible',
            button_delete_partnerspayload_label: 'Supprimer',

            // field_query_select_placeholder: 'Sélectionnez votre élément...',
            field_user_placeholder: 'Sélectionnez un utilisateur',
            field_owner_placeholder: 'Sélectionnez le propriétaire',
            field_organization_placeholder: 'Sélectionnez une organisation',
            field_role_placeholder: 'Sélectionnez le rôle',
            field_platform_placeholder: 'Sélectionnez une plateforme',
            field_membership_placeholder: 'Sélectionnez un membre',
            field_bank_placeholder: 'Sélectionnez une banque',
            field_merchant_placeholder: 'Sélectionnez un marchand',
            field_id_label: 'ID',
            field_id_status: 'Status',
            field_id_tag: 'Tag',
            field_id_createdAt: 'Date Création',
            column_createdat_label:'Date de création',

            column_id_label: 'Identifiant',
            column_email_label: 'Email',
            column_lastname_label: 'Nom',
            column_firstname_label: 'Prénom',
            column_name_label: 'Nom',
            column_store_id_label: 'ID du Magasin',
            column_store_name_label: 'Magasin',
            column_store_address_label: 'Adresse du Magasin',
            column_address_label: 'Adresse',
            column_store_merchant_name_label: 'Nom du Marchand',
            column_partnerName_label: 'Nom du POS',
            column_merchant_name_label: 'Nom du marchand',
            column_merchant_label: 'Marchand',
            column_amount_label: 'Montant',
            column_currency_label: 'Devise',
            column_created_at_label: 'Date de création',
            column_create_date_label: 'Date de création',
            column_updatedAt_label: 'Date de modification',
            column_date_label: 'Date d\'impression',
            column_update_date_label: 'Heure',
            column_actions_label: 'Actions',
            column_user_label: 'Utilisateur',
            column_organization_label: 'Organisation',
            column_code_label: 'Code',
            column_role_label: 'Rôle',
            column_bank_label: 'Banque',
            column_reference_label: 'Référence',
            column_transactions_label: 'Transactions',
            column_receipts_label: 'Reçus',
            column_store_label: 'Magasin',
            column_external_reference_label: 'Référence externe',
            column_owner_label: 'Propriétaire',
            column_paymentName_label:'Nom du Paiement TPE',
            column_siret_label: 'Siret',
            column_created_consumer_label: 'Membre depuis',
            column_partner_payload_partner_label: 'Partenaire',
            column_partner_payload_status_label: 'Statut',
            column_matches_amount: 'Montant',
            total_result_label: 'Total de résultats',
            select_table_label: 'Table',
            select_field_label: 'Champs',

            screen_home_label: 'Accueil',
            screen_receipts_label: 'Reçus',
            screen_receipt_label: 'Reçu n°{{id}}',
            screen_users_label: 'Utilisateurs',
            screen_user_label: 'Utilisateur {{id}}',
            screen_banks_label: 'Banques',
            screen_bank_label: 'Banque {{id}}',
            screen_stores_label: 'Magasins',
            screen_store_label: 'Magasin {{id}}',
            screen_partners_payloads_label: 'Payloads',
            screen_partner_payload_label: 'Payload {{id}}',
            screen_transactions_label: 'Transactions',
            screen_transaction_label: 'Transaction {{id}}',
            screen_merchants_label: 'Marchands',
            screen_merchant_label: 'Marchand {{id}}',
            screen_matches_label: 'Matchs',
            screen_match_label: 'Match {{id}}',
            screen_organizations_label: 'Organisations',
            screen_organization_label: 'Organisation {{id}}',
            screen_platforms_label: 'Plateformes',
            screen_platform_label: 'Plateforme {{id}}',
            screen_tokens_label: 'Jetons de sécurité',
            screen_token_label: 'Jeton de sécurité {{id}}',
            screen_memberships_label: 'Droits Utilisateurs',
            screen_membership_label: 'Membre',
            screen_user_profile_label: 'Mes informations',
            screen_consumers_label: 'Consommateurs',
            screen_consumer_label: 'Consommateur',
            screen_payments_label: 'Paiements',
            screen_payment_label: 'Paiement',
            screen_denied_label: 'Accès non autorisé, vous ne disposez pas des bons droits. Contactez votre administateur.',

            screen_generic_list_table_empty: 'Aucun élément disponible.',
            screen_users_list_table_empty: 'Aucun utilisateur disponible.',
            screen_merchants_list_table_empty: 'Aucun marchand disponible.',

            menu_users_label: 'Utilisateurs',
            menu_receipts_label: 'Reçus',
            menu_merchant_payments_label: 'Paiements',
            menu_transactions_label: 'Transactions',
            menu_banks_label: 'Banques',
            menu_stores_label: 'Magasins',
            menu_merchants_label: 'Marchands',
            menu_matches_label: 'Matchs',
            menu_platforms_label: 'Plateformes',
            menu_organizations_label: 'Organisations',
            menu_tokens_label: 'Jetons de Sécurité',
            menu_memberships_label: 'Droits Utilisateurs',
            menu_consumers_label: 'Consommateurs',
            menu_partner_payload_label: 'Payloads des partenaires',
            menu_search_screen_label: 'Rechercher des Reçus',
            menu_search_label: 'Rechercher',
            menu_section_activity_title: 'Activité',
            menu_section_settings_title: 'Paramétrages',
            menu_section_merchants_title: 'Marchands',
            menu_section_banks_title: 'Banques',
            menu_section_organizations_title: 'Organisations',

            id_merchant_profile: 'Identifiant KillBills',
            name_merchant_profile: 'Nom du marchand',
            statReceipts_merchant_profile: 'Nombre de reçus',
            statMatches_merchant_profile: 'Nombre de matchs',
            statStores_merchant_profile: 'Nombre de magasins',
            hmac_merchant_profile: 'Hmac',
            empty: 'vide',
            merchant_title_profile: 'Détail marchand :',
            user_title_profile: 'Détail utilisateur :',

            id: 'ID :',
            lastName: 'Nom :',
            firstName: 'Prénom :',
            email: 'Email :',
            name: 'Nom :',
            consumer: 'Consommateur :',
            merchantName: 'Nom de l\'entreprise :',
            storeName: 'Nom du magasin :',
            createdAt: 'Date de création :',
            updatedAt: 'Heure :',
            date: 'Date d\'impression :',
            payloadId: 'ID payload :',
            amount: 'Montant :',
            reference: 'Référence :',
            payment: 'Paiement :',
            bankName: 'Nom de la banque :',
            currency: 'Devise :',
            covers: 'Nombre de couverts :',
            taxAmount: 'Montant de la taxe :',
            taxes: 'Taxe :',
            taxDescription: 'Type de taxe :',
            match: 'Match :',
            matchable: 'Matchable :',
            matchable_value: 'Prêt à matcher',
            non_matchable_value: 'Non matchable',
            address: 'Adresse :',
            externalReference: 'Référence :',
            degradedReference: 'Référence dégradée :',
            lastFour: '4 derniers chiffres :',
            bin: 'BIN :',
            partnerName: 'POS :',
            scheme: 'Scheme :',
            paymentName: 'Nom du terminal :',
            headerTicket: 'Header ticket :',
            footerTicket: 'Footer ticket :',
            status: 'Status :',
            tag: 'Tag :',
            owner_id: 'ID propriétaire :',
            code: 'Code :',
            createdAt_tab_label: 'Date de création :',
            receiptId_tab_label: 'Reçu ID :',
            receiptId: 'Reçu ID :',
            transactionId_tab_label: 'Transaction ID :',
            public_tab_label: 'Public :',
            editStore_public:'Public',
            transaction: 'ID transaction :',
            receipt: 'ID reçu :',
            userFirstName: 'Prénom :',
            userLastName: 'Nom :',
            role: 'Rôle :',
            siret: 'Siret :',
            siren: 'Siren :',
            userEmail: 'Email :',
            organizationName: 'Nom de l\'organisation :',
            organization: 'Organisation :',
            statStores: 'Nombre de magasins :',
            statReceipts: 'Nombre de reçus :',
            statTransactions: 'Nombre de transactions :',
            stat_matches: 'Nombre de matchs :',
            statMatches: 'Nombre de matchs :',
            statConsumers: 'Nombre de consommateurs :',
            location: 'GPS :',
            menu_api_doc_label: 'Documentation API',

            //#Operators
            ids: 'Liste d\'identifiants',
            in: 'Contient',
            eq: 'Égal à',
            lt: 'Inférieur à',
            gt: 'Supérieur à',
            lte: 'Inférieur ou égal à - x <=',
            gte: 'Supérieur ou égal à - x >=',
            prefix: 'Préfixe',
            exists: 'Existe',
            wildcard: 'Caractères génériques',
            regex: 'Regex',
            range: 'Intervalle - [x]',
            rangelt: 'Intervalle minimum exclu - ]< x]',
            rangegt: 'Intervalle maximum exclu - [x <[',
            rangegtlt: 'Intervalle borné - ]x[',

            //#Types
            string: 'Chaine de caractères',
            int: 'Nombre entier',
            boolean: 'Booléen',
            float: 'Nombre décimal',

            field_start_date_time_label: 'Date de début',
            field_end_date_time_label: 'Date de fin',
            field_placeholder_label: 'Champs',
            operator_placeholder_label: 'Operateur',
            value_placeholder_label: 'Valeur',
            type_placeholder_label: 'Type',
            field_field_label: 'Champs',
            field_operator_label: 'Opérateur',
            field_value_label: 'Valeur',
            field_type_label: 'Type',

            button_display_label: 'Afficher',
            button_add_label: 'Ajouter',
            button_edit_label: 'Modifier',

            form_edit_user_title: 'Modification de l\'utilisateur',
            form_edit_user_subtitle: 'Vous pouvez mettre à jour les données de l\'utilisateur ci-dessous',

            user_menu_profile_label: 'Mes informations',

            modal_confirm_delete_bank_title: 'Confirmer la suppression',
            modal_confirm_delete_bank_text: 'Êtes-vous certain de vouloir supprimer cette banque ?',
            modal_confirm_delete_store_title: 'Confirmer la suppression',
            modal_confirm_delete_store_text: 'Êtes-vous certain de vouloir supprimer ce magasin ?',
            modal_confirm_delete_merchant_title: 'Confirmer la suppression',
            modal_confirm_delete_merchant_text: 'Êtes-vous certain de vouloir supprimer ce marchand ?',
            modal_confirm_delete_organization_title: 'Confirmer la suppression',
            modal_confirm_delete_organization_text: 'Êtes-vous certain de vouloir supprimer cette organisation ?',
            modal_confirm_delete_user_title: 'Confirmer la suppression',
            modal_confirm_delete_user_text: 'Êtes-vous certain de vouloir supprimer cet utilisateur ?',
            modal_confirm_delete_membership_title: 'Confirmer suppression',
            modal_confirm_delete_membership_text: 'Êtes-vous certain de vouloir supprimer ce membre ?',
            modal_confirm_delete_receipt_title: 'Confirmer suppression',
            modal_confirm_delete_receipt_text: 'Êtes-vous certain de vouloir supprimer ce reçu ?',
            modal_confirm_delete_consumer_title: 'Confirmer suppression',
            modal_confirm_delete_consumer_text: 'Êtes-vous certain de vouloir supprimer ce consommateur ?',

            button_delete_membership_label: 'Supprimer',
            button_delete_user_label: 'Supprimer',
            button_delete_bank_label: 'Supprimer',
            button_delete_store_label: 'Supprimer',
            button_delete_merchant_label: 'Supprimer',
            button_delete_organization_label: 'Supprimer',
            button_delete_receipt_label: 'Supprimer',
            button_delete_consumer_label: 'Supprimer',
            button_link_organization_label: 'Voir l\'organisation',
            button_link_transaction_label: 'Voir la transaction',
            button_link_receipt_label: 'Voir le reçu',
            button_link_edit_label: 'Modifier',
            button_link_edit_password_label: 'Modifier mot de passe',
            button_link_json_label: 'JSON',

            tab_organization_infos_title : 'Détails',
            tab_organization_members_title : 'Membres',
            tab_merchant_infos_title: 'Détails',
            tab_merchant_stores_title: 'Magasins',
            tab_bank_infos_title: 'Détails',
            tab_bank_consumers_title: 'Consommateurs',
            tab_bank_transactions_title: 'Transactions',
            tab_bank_matches_title: 'Matchs',

            membership_user_label: 'Utilisateur',
            membership_admin_label: 'Administrateur',
            membership_owner_label: 'Propriétaire',

            homeScreen_transactions: 'Total Transactions',
            homeScreen_receipts: 'Total Reçus',
            homeScreen_matches: 'Total Matchs',
            homeScreen_titleBank: '42',
            homeScreen_subtitleBank: 'Nb de Banques',
            homeScreen_titleMerchant: '256',
            homeScreen_subtitleMerchant: 'Nb Marchands',
            homeScreen_titleStore: '3025',
            homeScreen_subtitleStore: 'Nb de Boutiques',
            homeScreen_titleAppz: '198',
            homeScreen_subtitleAppz: 'Nb de logiciels de caisse partenaires',
            homeScreen_customers: 'Clients',
            homeScreen_newCustomers: 'Nouveaux Clients',
            homeScreen_transactionsGoal: 'Objectif des Transactions',
            homeScreen_matchesGoal: 'Objectif des Matchs',
            homeScreen_customersGoal: 'Objectif Clients',
            homeScreen_stats_one: 'nombre de vente début d\'année',
            homeScreen_stats_two: 'nombre de vente milieu d\'année',
            homeScreen_stats_three: 'nombre de vente fin d\'année',
            homeScreen_first_merchant_label: 'Premier marchand',
            homeScreen_first_store_label: 'Premier magasin',
            homeScreen_transactions_label: 'Nombre de transactions',
            homeScreen_matches_label: 'Nombre de matchs',
            homeScreen_receipts_label: 'Nombre de reçus',
            homeScreen_users_label: 'Nombre d\'utilisateurs',
            homeScreen_transactions_per_receipts_label: 'Ratio transactions/reçus',
            homeScreen_receipts_per_transactions_label: 'Ratio reçus/transactions',
            homeScreen_matches_per_receipts_percent_label: 'Ratio matchs/reçus',
            homeScreen_matches_per_transactions_percent_label: 'Ration matchs/transactions',
            homeScreen_store_counter: 'Nombre de magasins',
            homeScreen_stores_goal_label: 'Objectif 10.000 magasins partenaires (+9000%)',
            homeScreen_stores_label: 'Nombre de magasins',
            homeScreen_receipts_chart_label: 'Reçus',
            homeScreen_transactions_chart_label: 'Transactions',
            homeScreen_receipts_goal_label: 'Objectif 1.000.000 reçus',
            homeScreen_transactions_goal_label: 'Objectif 10.000 transactions',
            receipts_on_last_24: 'Reçus sur les dernières 24 heures',
            transactions_on_last_24: 'Transactions sur les dernières 24 heures',
            transactions_percentage_increase: 'de transactions',
            receipts_percentage_increase: 'de reçus',
            matches_on_last_24: 'Matchs sur les dernières 24 heures',
            matches_percentage_increase: 'de matchs',

            editStore_address: 'Adresse',
            editStore_siret: 'SIRET',
            editStore_paymentName: 'Nom du Paiement TPE',
            editStore_headerTicket: 'Header du ticket',
            editStore_footerTicket: 'Footer du ticket',
            editStore_location: 'Coordonées GPS',
            editTransaction_amount: 'Montant',
            editTransaction_storeName: 'Nom du magasin',
            editTransaction_date: 'Date',
            screen_search_input: 'Saisir votre requête',
            /*permet au composant Textfield de ne pas afficher un helper*/ empty_helper: '',

            footer_home_link_label: 'Accueil',
            footer_services_link_label: 'Services',
            footer_about_us_link_label: 'À propos de nous',
            footer_team_link_label: 'L\'équipe',
            footer_privacy_terms_link_label: 'Confidentialité et modalités',
            footer_phone_label: '118-218',
            footer_email_label: 'killbills@killbills.com',
            footer_address_label: '62 rue Jean Jacques Rousseau',
            footer_zipCode_label: '75001',
            footer_city_label: 'Paris',
            footer_copyright_label: '© 2022  KillBills',
            footer_description_label: 'Les tickets de caisse, autrement.',
            footer_facebook_label: 'https://www.facebook.fr',
            project_name: '',
        }
    },
    'en-GB': {
        translation: {
            /*allows the Textfield component not to display a helper*/ empty_helper: '',
            error_404: 'The requested page does not exist or has been deleted',
            role_user_label: 'User',
            role_admin_label: 'Admin',
            role_owner_label: 'Owner',
            choose_a_type: 'Choose a type',
            choose_an_operator: 'Choose an operator',
            add_a_condition: 'Filter',
            search: 'Search',
            reset_search: 'Reset search',
            Field: 'Field',
            Value: 'Value',
            Remove: 'Supprimer',
            'yes': 'Yes',
            'no': 'No',
            editStore_public: 'Public',
            Search_by_id: 'Search by ID',
            'By id': 'By ID',
            'Query builder': 'Query builder',
            column_POS_label: 'POS Name',
            // field_query_select_label: 'Selection',
            field_user_label: 'User',
            field_owner_label: 'Owner',
            field_organization_label: 'Organization',
            field_role_label: 'Role',
            field_platform_label: 'Platform',
            field_membership_label: 'Membership',
            field_bank_label: 'Bank',
            field_merchant_label: 'Merchant',
            field_partner_payload_partner_label: 'Partner :',
            field_partner_payload_dbId_label: 'ID in DB',
            field_partner_payload_status_label: 'Status :',
            modal_confirm_delete_partnerspayload_title: 'Delete payload ?',
            modal_confirm_delete_partnerspayload_text: 'This action is irreversible',
            button_delete_partnerspayload_label: 'Delete',
            // field_query_select_placeholder: 'Select element...',
            field_user_placeholder: 'Select user',
            field_owner_placeholder: 'Select owner...',
            field_organization_placeholder: 'Select organization',
            field_role_placeholder: 'Select role',
            field_platform_placeholder: 'Select platform',
            field_membership_placeholder: 'Select membership',
            field_bank_placeholder: 'Select bank',
            field_merchant_placeholder: 'Select merchant',
            filed_id_label: 'ID',
            field_id_status: 'Status',
            field_id_tag: 'Tag',
            field_id_createdAt: 'Date Created',
            column_createdat_label:'Created at',
            column_id_label: 'ID',
            column_email_label: 'Email',
            column_lastname_label: 'Last Name',
            column_firstname_label: 'First Name',
            column_name_label: 'Name',
            column_store_id_label: 'ID Store',
            column_store_name_label: 'Store Name',
            column_store_address_label: 'Store Address',
            column_address_label: 'Address',
            column_store_merchant_name_label: 'Merchant Name',
            column_merchant_name_label: 'Merchant Name',
            column_partnerName_label: 'POS name',
            column_merchant_label: 'Merchant',
            column_amount_label: 'Amount',
            column_currency_label: 'Currency',
            column_create_date_label: 'Creation date',
            column_updatedAt_label: 'Updated at',
            column_date_label: 'Print date',
            column_update_date_label: 'Hour',
            column_actions_label: 'Actions',
            column_user_label: 'User',
            column_organization_label: 'Organization',
            column_code_label: 'Code',
            column_role_label: 'Role',
            column_bank_label: 'Bank',
            column_reference_label: 'Reference',
            column_transactions_label: 'Transactions',
            column_receipts_label: 'Receipts',
            column_store_label: 'Store',
            column_external_reference_label: 'External reference',
            column_owner_label: 'Owner',
            column_paymentName_label: 'TPE Payment Name',
            column_siret_label: 'Store Legit Number',
            column_created_consumer_label: 'Member since',
            column_partner_payload_partner_label: 'Partner',
            column_partner_payload_status_label: 'Status',
            column_matches_amount: 'Amount',
            total_result_label: 'Total of results :',
            select_table_label: 'Table',
            select_field_label: 'Field',

            screen_home_label: 'Home',
            screen_receipts_label: 'Receipts',
            screen_receipt_label: 'Receipt {{id}}',
            screen_users_label: 'Users',
            screen_user_label: 'User {{id}}',
            screen_banks_label: 'Banks',
            screen_bank_label: 'Bank {{id}}',
            screen_stores_label: 'Stores',
            screen_store_label: 'Store {{id}}',
            screen_partners_payloads_label: 'Payloads',
            screen_partner_payload_label: 'Payload {{id}}',
            screen_transactions_label: 'Transactions',
            screen_transaction_label: 'Transaction {{id}}',
            screen_merchants_label: 'Merchants',
            screen_merchant_label: 'Merchant {{id}}',
            screen_matches_label: 'Matches',
            screen_match_label: 'Match {{id}}',
            screen_organizations_label: 'Organizations',
            screen_organization_label: 'Organization {{id}}',
            screen_platforms_label: 'Platforms',
            screen_platform_label: 'Platform {{id}}',
            screen_tokens_label: 'Tokens',
            screen_token_label: 'Token {{id}}',
            screen_memberships_label: 'Users Rights',
            screen_membership_label: 'Member',
            screen_user_profile_label: 'Informations',
            screen_consumers_label: 'Consumers',
            screen_consumer_label: 'Consumer',
            screen_denied_label: 'Unauthorized access, you have no rights. Contact your administrator.',

            screen_generic_list_table_empty: 'No records available.',
            screen_users_list_table_empty: 'No users available.',
            screen_merchants_list_table_empty: 'No merchants available.',
            menu_users_label: 'Users',
            menu_receipts_label: 'Receipts',
            menu_transactions_label: 'Transactions',
            menu_banks_label: 'Banks',
            menu_stores_label: 'Stores',
            menu_merchants_label: 'Merchants',
            menu_matches_label: 'Matches',
            menu_platforms_label: 'Platforms',
            menu_organizations_label: 'Organizations',
            menu_tokens_label: 'Tokens',
            menu_memberships_label: 'User Rights',
            menu_consumers_label: 'Consumers',
            menu_search_screen_label: 'Search Receipts',
            menu_search_label: 'Search',
            menu_section_activity_title: 'Activity',
            menu_section_settings_title: 'Settings',
            menu_section_merchants_title: 'Merchants',
            menu_section_banks_title: 'Banks',
            menu_section_organizations_title: 'Organizations',

            screen_search_input: 'Enter you request',
            id: 'ID :',
            lastName: 'Last Name :',
            firstName: 'First Name :',
            email: 'Email :',
            name: 'Name :',
            consumer: 'Consumer :',
            merchantName: 'Company name :',
            storeName: 'Shop name :',
            createdAt: 'Date received :',
            updatedAt: 'Hour :',
            date: 'Print date :',
            payloadId: 'Payload ID :',
            amount: 'Amount :',
            bankName: 'Banque :',
            reference: 'Reference :',
            payment: 'Payment :',
            currency: 'Currency :',
            covers: 'Total covers :',
            taxAmount: 'Tax amount :',
            taxes: 'Tax :',
            taxDescription: 'Tax description :',
            match: 'Match :',
            matchable: 'Matchable :',
            matchable_value: 'Ready to match',
            non_matchable_value: 'Unmatchable',
            address: 'Address :',
            externalReference: 'Reference :',
            degradedReference: 'Degraded reference :',
            lastFour: '4 last digits :',
            bin: 'BIN :',
            partnerName: 'POS :',
            scheme: 'Scheme :',
            paymentName: 'Terminal name :',
            headerTicket: 'Ticket header :',
            footerTicket: 'Ticket footer :',
            status: 'Status :',
            tag: 'Tag :',
            owner_id: 'Owner id :',
            code: 'Code :',
            createdAt_tab_label: 'Creation date :',
            receiptId_tab_label: 'Receipt ID :',
            receiptId: 'Receipt ID :',
            transactionId_tab_label: 'Transaction ID :',
            public_tab_label: 'Public :',
            transaction: 'ID transaction :',
            receipt: 'ID receipt :',
            userFirstName: 'First Name :',
            userLastName: 'Last Name :',
            role: 'Role :',
            siret: 'Store legit number :',
            siren: 'Siren number :',
            userEmail: 'Email :',
            organizationName: 'Organization name :',
            organization: 'Organization :',
            statStores: 'Number of stores :',
            statReceipts: 'Number of receipts :',
            statMatches: 'Number of matches :',
            statTransactions: 'Number of transactions :',
            stat_matches: 'Number of matches :',
            statConsumers: 'Number of consumers :',
            location: 'GPS :',

            //#Operators
            ids: 'List ID',
            in: 'Contains',
            eq: 'Equal to',
            lt: 'Loweer than',
            gt: 'Greater than',
            lte: 'Lower or equal to - x <=',
            gte: 'Greater or equal to - x >=',
            prefix: 'Prefix',
            exists: 'Exists',
            wildcard: 'Généric caracters',
            regex: 'Regex',
            range: 'Range - [x]',
            rangelt: 'Interval minimum excluded - ]< x]',
            rangegt: 'Interval maximum excluded - [x <[',
            rangegtlt: 'Open interval - ]x[',

            field_start_date_time_label: 'Start date',
            field_end_date_time_label: 'End date',
            field_placeholder_label: 'Field',
            operator_placeholder_label: 'Operator',
            value_placeholder_label: 'Value',
            type_placeholder_label: 'Type',
            field_field_label: 'Field',
            field_operator_label: 'Operator',
            field_value_label: 'Value',
            field_type_label: 'Type',
            menu_api_doc_label: 'API Doc',

            merchant_title_profile: 'Merchant informations :',
            user_title_profile: 'User informations :',
            id_merchant_profile: 'KillBills ID',
            name_merchant_profile: 'Merchant name',
            statReceipts_merchant_profile: 'Number of receipts',
            statMatches_merchant_profile: 'Number of matchs',
            statStores_merchant_profile: 'Number of stores',
            hmac_merchant_profile: 'Hmac',
            empty: 'empty',

            //#Types
            string: 'String',
            int: 'Integer',
            boolean: 'Boolean',
            float: 'Float',

            button_display_label: 'Display',
            button_add_label: 'Add',
            button_edit_label: 'Edit',

            user_menu_profile_label: 'Profile',

            modal_confirm_delete_bank_title: 'Confirm deletion',
            modal_confirm_delete_bank_text: 'Are you sure you want to eliminate this bank ?',
            modal_confirm_delete_store_title: 'Confirm deletion',
            modal_confirm_delete_store_text: 'Are you sure you want to eliminate this store ?',
            modal_confirm_delete_merchant_title: 'Confirm deletion',
            modal_confirm_delete_merchant_text: 'Are you sure you want to eliminate this merchant ?',
            modal_confirm_delete_organization_title: 'Confirm deletion',
            modal_confirm_delete_organization_text: 'Are you sure you want to eliminate this organization ?',
            modal_confirm_delete_user_title: 'Confirm deletion',
            modal_confirm_delete_user_text: 'Are you sure you want to eliminate this user ?',
            modal_confirm_delete_membership_title: 'Confirm deletion',
            modal_confirm_delete_membership_text: 'Are you sure you want to eliminate this membership ?',
            modal_confirm_delete_receipt_title: 'Confirm deletion',
            modal_confirm_delete_receipt_text: 'Are you sure you want to eliminate this receipt ?',
            modal_confirm_delete_consumer_title: 'Confirm deletion',
            modal_confirm_delete_consumer_text: 'Are you sure you want to eliminate this consumer ?',

            button_edit_password_label: 'Edit password',
            button_delete_membership_label: 'Delete',
            button_delete_user_label: 'Delete',
            button_delete_bank_label: 'Delete',
            button_delete_store_label: 'Delete',
            button_delete_merchant_label: 'Delete',
            button_delete_organization_label: 'Delete',
            button_delete_receipt_label: 'Delete',
            button_delete_consumer_label: 'Delete',
            button_link_organization_label: 'See organization',
            button_link_transaction_label: 'See transaction',
            button_link_receipt_label: 'See receipt',
            button_link_json_label: 'JSON',

            tab_organization_infos_title : 'Details',
            tab_organization_members_title : 'Members',
            tab_merchant_infos_title: 'Details',
            tab_merchant_stores_title: 'Stores',
            tab_bank_infos_title: 'Details',
            tab_bank_consumers_title: 'Consumers',
            tab_bank_transactions_title: 'Transactions',
            tab_bank_matches_title: 'Matches',

            membership_user_label: 'User',
            membership_admin_label: 'Admin',
            membership_owner_label: 'Owner',

            homeScreen_transactions: 'Total Transactions',
            homeScreen_receipts: 'Total Receipts',
            homeScreen_titleBank: '42',
            homeScreen_subtitleBank: 'Nb Banks',
            homeScreen_titleMerchant: '256',
            homeScreen_subtitleMerchant: 'Nb Merchants',
            homeScreen_titleStore: '3025',
            homeScreen_subtitleStore: 'Nb Stores',
            homeScreen_titleAppz: '198',
            homeScreen_subtitleAppz: 'Nb Cash Register Software Partners',
            homeScreen_matches: 'Matches',
            homeScreen_customers: 'Customers',
            homeScreen_newCustomers: 'New Customers',
            homeScreen_transactionsGoal: 'Transactions goal',
            homeScreen_matchesGoal: 'Matchs goal',
            homeScreen_customersGoal: 'Customers goal',
            homeScreen_stats_one: 'number of sales at the beginning of the year',
            homeScreen_stats_two: 'number of mid-year sales',
            homeScreen_stats_three: 'number of year-end sales',
            homeScreen_first_merchant_label: 'First merchant',
            homeScreen_first_store_label: 'First store',
            homeScreen_transactions_label: 'Total of transactions',
            homeScreen_matches_label: 'Total of matches',
            homeScreen_receipts_label: 'Total of receipts',
            homeScreen_users_label: 'Total of users',
            homeScreen_transactions_per_receipts_label: 'Ratio transactions/receipts',
            homeScreen_receipts_per_transactions_label: 'Ratio receipts/transactions',
            homeScreen_matches_per_receipts_percent_label: 'Ratio matches/receipts',
            homeScreen_matches_per_transactions_percent_label: 'Ration matches/transactions',
            homeScreen_store_counter: 'Total of shops',
            homeScreen_stores_label: 'Total of shops',
            homeScreen_store_goal: 'Shops goal',
            homeScreen_receipts_chart_label: 'Receipts',
            homeScreen_transactions_chart_label: 'Transactions',
            homeScreen_receipts_goal_label: 'Receipts goal 1.000.000',
            homeScreen_transactions_goal_label: 'Transactions goal 10.000',
            receipts_on_last_24: 'Receipts on last 24 hours',
            transactions_on_last_24: 'Transactions on last 24 hours',
            transactions_percentage_increase: 'more transactions',
            receipts_percentage_increase: 'more receipts',
            matches_on_last_24: 'Matches on last 24 hours',
            matches_percentage_increase: 'more matches',

            editStore_address: 'Address',
            editStore_siret: 'SIRET',
            editStore_paymentName: 'TPE Payment Name',
            editStore_headerTicket: 'Ticket Header',
            editStore_footerTicket: 'Ticket Footer',
            editStore_location: 'GPS coordinates',
            editTransaction_amount: 'Amount',
            editTransaction_storeName: 'Store name',
            editTransaction_date: 'Date',

            footer_home_link_label: 'Home',
            footer_services_link_label: 'Services',
            footer_about_us_link_label: 'About us',
            footer_team_link_label: 'Team',
            footer_privacy_terms_link_label: 'Privacy terms',
            footer_phone_label: '118-218',
            footer_email_label: 'killbills@killbills.com',
            footer_address_label: '62 street Jean Jacques Rousseau',
            footer_zipCode_label: '75001',
            footer_city_label: 'Paris',
            footer_copyright_label: '© 2022  KillBills',
            footer_description_label: 'The receipts, otherwise.',
            project_name: '',
        }
    }
}
export default translations